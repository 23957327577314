<template>
  <div>
    <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="62" width="100%" class="bookingimages-viewpage"
    elevation="7">
      <v-btn x-small fab elevation="0" color="transparent" dark @click="$parent.removeActiveModule()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <span class="px-2 body-1 font-weight-medium" :style="`color: ${ systemDetails.textcolor }`">
        {{ $t('bookingImages') }} ({{ moduleName }})
      </span>
      <v-spacer></v-spacer>
      <v-btn x-small fab elevation="0" color="transparent" dark @click="fileUploadDialog">
        <v-icon> mdi-cloud-upload </v-icon>
      </v-btn>
    </v-app-bar>
    <template v-if="loading">
      <v-progress-linear value="15"></v-progress-linear>
    </template>
    <template v-else>
      <template no-gutters v-if="listOfModuleImages.length">
        <draggable v-model="listOfModuleImages" class="row mx-1" id="fieldDroppingArea" @end="setOrder()">
          <template v-for="(item, index) in listOfModuleImages">
            <v-col cols="12" xl="2" lg="2" :key="index">
              <div>
                <v-img :src="`${api_baseurl}${userDetails.domain}/module_images/${item.fileguid}`"
                  :lazy-src="`${api_baseurl}${userDetails.domain}/module_images/${item.fileguid}`"
                  :aspect-ratio="16/9" class="grey lighten-2" style="position: relative;">
                  <v-overlay :value="true" :absolute="true" class="d-flex align-end pl-lg-4" opacity="0.35">
                    <p class="subheading font-weight-bold text-overflow" style="letter-spacing: 1px; text-shadow: 1.75px 1.75px black;"> {{ item.notes }} </p>
                  </v-overlay>
                  <template #placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal white--text" style="height: 100%;">
                  <v-flex class="text-center align-self-center">
                    <!-- <v-icon dark class="mr-3">mdi-download</v-icon> -->
                    <v-icon dark @click="noteDialogHandler(item)" class="mr-4">mdi-note-text</v-icon>
                    <v-icon dark color="error" @click="deleteImageHandler(item)">mdi-delete-circle</v-icon>
                  </v-flex>
                </div>
              </div>
            </v-col>
          </template>
        </draggable>
      </template>
      <v-container fluid v-else>
        <v-row>
          <v-col cols="12">
            <v-alert :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" dark icon="mdi-image-off" border="left" prominent outlined>
              {{ $t('noImagesUploaded') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-dialog persistent v-model="uploadFileDialog" width="700px">
       <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor || '#1976d2'};color:${systemDetails.textcolor || 'white'}`">
          {{ $t('uploadImage') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form ref="fileDialog">
            <v-row no-gutters>
              <v-col cols="12">
                <v-file-input accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera" multiple outlined dense v-model="files" :rules="$_requiredValidation" hide-details></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" id="deleteConfirm" :loading="loading" @click="uploadImageHandler" class="mr-3">{{ $t('upload') }}</v-btn>
            <v-btn color="error" id="deleteClose" @click="closeFileDialog">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Notes Update dialog-->
     <v-dialog persistent v-model="notesDialog" width="700px">
       <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor || '#1976d2'};color:${systemDetails.textcolor || 'white'}`">
          {{ $t('notes') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form ref="notesDialog">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field outlined dense v-model="notesObj.notes" :rules="$_requiredValidation" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" :loading="loading" @click="updateNotesHandler()" class="mr-3">{{ $t('update') }}</v-btn>
            <v-btn color="error" @click="closeNotesDialogHandler">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
export default {
  props: ['moduleName', 'recordId'],
  components: {
    draggable
  },
  data () {
    return {
      loading: false,
      uploadFileDialog: false,
      files: null,
      listOfModuleImages: [],
      api_baseurl: '',
      tenantName: '',
      dragging: false,
      notesDialog: false,
      notesObj: {}
    }
  },
  computed: {
    ...mapGetters(['userDetails', 'systemDetails'])
  },
  mounted () {
    this.getModuleImages()
    this.api_baseurl = process.env.VUE_APP_IMAGE_URL
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.moduleName) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'Deleted sucess' })
        this.$store.commit('hideDialog')
        this.getModuleImages()
      }
    })
  },
  methods: {
    fileUploadDialog () {
      this.uploadFileDialog = true
    },
    noteDialogHandler (item) {
      this.notesDialog = true
      this.notesObj = this.$formatter.cloneVariable(item)
    },
    closeNotesDialogHandler () {
      this.notesDialog = false
      this.notesObj = {}
    },
    updateNotesHandler () {
      this.loading = true
      this.$api.execute('post', `moduleimages/${this.moduleName}/set_notes/${this.notesObj._id}`, { notes: this.notesObj.notes }).then(() => {
        this.loading = false
        this.closeNotesDialogHandler()
        this.getModuleImages()
      })
    },
    closeFileDialog () {
      this.uploadFileDialog = false
      this.$refs.fileDialog.reset()
      this.files = null
    },
    uploadImageHandler () {
      if (this.$refs.fileDialog.validate()) {
        var formData = new FormData()
        const filesList = this.files
        filesList.forEach(file => {
          formData.append(file.name, file)
        })
        this.loading = true
        this.$api.execute('post', `moduleimages/${this.moduleName}/upload?id=${this.recordId}`, formData).then(() => {
          this.closeFileDialog()
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'uploadedSuccess' })
          this.getModuleImages()
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getModuleImages () {
      this.loading = true
      this.$api.execute('get', `moduleimages/${this.moduleName}/${this.recordId}`).then(response => {
        this.listOfModuleImages = response.data.sort(function (a, b) {
          return a.order - b.order
        })
        this.loading = false
      })
    },
    setOrder () {
      const imageIds = this.listOfModuleImages.map((x) => x._id)
      this.$api.execute('post', `moduleimages/${this.moduleName}/set_order/${this.recordId}`, imageIds)
    },
    deleteImageHandler (item) {
      const payload = { ids: [item._id], url: `moduleimages/${this.moduleName}/delete_multiple`, module: this.moduleName }
      this.$store.commit('showDeleteDialog', payload)
    }
  }
}
</script>
<style scoped>
  .bookingimages-viewpage .v-toolbar__content {
    padding: 0 !important;
  }
</style>
